import * as React from "react"
import Layout from "../components/layout"
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Section from "../components/Section"
import Location from "../components/Location"
import {
  MaxContainer,
  ThreeColumnGrid,
  Deal,
  DealMain,
  SecondaryButton,
  Row,
} from '../styles/utility'
 
const OffersPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicSpecialOffers.edges[0].node.data
  return (
    <Layout>
      <SEO title="Special Dental Offers" noFollow={true} description="Explore the special dental offers available at Norwest Dental Care. We have a variety of dental offers and special deals. Click here for more information. " />
      <MaxContainer>
        <Row>
          <Section pageTitle="Special Dental Offers">
              <ThreeColumnGrid>
              {document.special_offers.map((offer, index) => (
                <Deal key={index}>
                    <DealMain>
                        <h3>{RichText.asText(offer.offer_price)}</h3>
                        <h3>{RichText.asText(offer.offer_title)}</h3>
                        <p>{RichText.asText(offer.offer_description)}</p>
                    </DealMain>
                    <SecondaryButton href="#trading-hours">Claim Offer</SecondaryButton>
                </Deal>
                ))}
              </ThreeColumnGrid>
            </Section>
            <Section>
              <Location />
            </Section>
        </Row>
      </MaxContainer>
    </Layout>
  )
}

export const query = graphql`
query Offers {
    allPrismicSpecialOffers {
      edges {
        node {
          data {
            special_offers {
              offer_description {
                text
              }
              offer_title {
                text
              }
              offer_price {
                text
              }
              offer_link {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default OffersPage